import React, { FC } from 'react'
import { Dialog, DialogContent, Grid, Button, DialogTitle } from '@material-ui/core'
import { Formik } from 'formik'
import * as yup from 'yup'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { useMutation } from '@apollo/react-hooks'
import { ResetCognitoUserPasswordMutation, ResetCognitoUserPasswordMutationVariables, Person } from 'generated/graphql'
import { Feedback, getSuccessFeedback, getErrorFeedback } from 'utils/feedback'
import { RESET_COGNITO_USER_PASSWORD } from 'graphql/common'

interface FormValues {
  Password: string
}

const REQUIRED = `is a required field`
const resetPasswordSchema = yup.object({
  Password: yup
    .string()
    .max(50, 'maximum 50 characters long')
    .min(6, 'minimum 6 characters long')
    .required(`Password ${REQUIRED}`)
})

interface ResetPasswordModalProps {
  onCloseResetPasswordModal: () => void
  isOpen: boolean
  selectedItem?: Person | null
  setAutoHideFeedback: (value: Feedback) => void
}

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  onCloseResetPasswordModal,
  isOpen,
  selectedItem,
  setAutoHideFeedback
}) => {
  const initialValues: FormValues = {
    Password: ''
  }

  const [resetCognitoUserPassword] = useMutation<
    ResetCognitoUserPasswordMutation,
    ResetCognitoUserPasswordMutationVariables
  >(RESET_COGNITO_USER_PASSWORD)

  return (
    <>
      <Dialog onClose={onCloseResetPasswordModal} open={isOpen} maxWidth={'sm'} fullWidth>
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent style={{ padding: 20 }}>
          <Formik
            onSubmit={values => {
              resetCognitoUserPassword({
                variables: {
                  input: {
                    UserName: selectedItem?.Email || '',
                    Password: values.Password
                  }
                }
              })
                .then(({ data }) => {
                  onCloseResetPasswordModal()
                  if (data?.resetAdminCognitoUserPassword) {
                    setAutoHideFeedback(getSuccessFeedback(`Password has been updated successfully`))
                  }
                })
                .catch(error => {
                  onCloseResetPasswordModal()
                  setAutoHideFeedback(getErrorFeedback(error.message))
                })
            }}
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
          >
            {props => {
              const { values, errors, touched, handleBlur, setFieldValue, handleSubmit } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="space-between" direction="row" spacing={4}>
                    <Grid item xs={12}>
                      <GridLayoutInput
                        fullWidth
                        id="password"
                        label="Enter new password"
                        textFieldBreakpoints={{ xs: 12, sm: 12 }}
                        name="Password"
                        value={values.Password}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('Password', e.target.value)}
                        required
                        error={!!errors.Password && !!touched.Password}
                      />
                    </Grid>

                    <Grid container item xs={12} justifyContent="flex-end">
                      <Button variant="contained" onClick={onCloseResetPasswordModal}>
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button color="secondary" variant="contained" type="submit">
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
